import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpen, faUser } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import * as PropTypes from 'prop-types';

class ContactHeader extends React.Component {
  static propTypes = {
    phoneNumber: PropTypes.string.isRequired,
    contactEmail: PropTypes.string.isRequired,
    servicesURL: PropTypes.string.isRequired,
  };

  render() {
    const buttonStyle = {
      backgroundColor: '#72ba70',
      color: 'white',
      fontSize: '12px',
      width: '100%',
      padding: '10px 30px',
    };

    const {
      facebookLink,
      linkedinLink,
      twitterLink,
      phoneNumber,
      contactEmail,
      servicesURL,
    } = this.props;
    return (
      <>
        {servicesURL && (
          <li>
            <a href={servicesURL} style={buttonStyle}>
              <FontAwesomeIcon icon={faUser} />
              &nbsp; Espace Pro
            </a>
          </li>
        )}
        {facebookLink && (
          <li>
            <a href={facebookLink} target="_blank">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </li>
        )}
        {linkedinLink && (
          <li>
            <a href={linkedinLink} target="_blank">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </li>
        )}
        {twitterLink && (
          <a href={twitterLink} target="_blank">
            <FontAwesomeIcon icon={faTwitterSquare} />
          </a>
        )}
        <li>
          <FontAwesomeIcon icon={faPhone} /> {phoneNumber}
        </li>
        <li>
          <FontAwesomeIcon icon={faEnvelopeOpen} /> {contactEmail}
        </li>
      </>
    );
  }
}

export default ContactHeader;
