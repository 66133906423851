import React from 'react';
import ContactHeader from './ContactHeader';
import Footer from './Footer';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

class Layout extends React.Component {
  static propTypes = {
    facebookURL: PropTypes.string,
    linkedinURL: PropTypes.string,
    twitterURL: PropTypes.string,
    contactEmail: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    servicesURL: PropTypes.string.isRequired,
  };

  render() {
    const {
      children,
      imageURL,
      facebookURL,
      linkedinURL,
      twitterURL,
      contactEmail,
      phoneNumber,
      servicesURL,
    } = this.props;
    const header = (
      <header id="header">
        <div className="header-top" />
        <div className="container-fluid main-menu">
          <div className="row align-items-center justify-content-between d-flex">
            <Link to={'/'}>
              <img src={imageURL} width={'300px'} />
            </Link>
            <nav id="nav-menu-container" className="mr-4">
              <ul
                className="nav-menu sf-js-enabled sf-arrows"
                style={{ touchAction: 'pan-y' }}
              >
                <ContactHeader
                  facebookLink={facebookURL}
                  linkedinLink={linkedinURL}
                  twitterLink={twitterURL}
                  contactEmail={contactEmail}
                  phoneNumber={phoneNumber}
                  servicesURL={servicesURL}
                />
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
    return (
      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          marginLeft: `0px`,
          marginRight: `0px`,
          fontFamily: 'Poppins, sans-serif',
        }}
      >
        {header}
        {children}
        <Footer
          facebookURL={facebookURL}
          linkedinURL={linkedinURL}
          twitterURL={twitterURL}
        />
      </div>
    );
  }
}

export default Layout;
