import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faLinkedin,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import { graphql, Link } from 'gatsby';

class Footer extends Component {
  render() {
    const { facebookURL, linkedinURL, twitterURL } = this.props;

    return (
      <footer className="footer-area section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6>À propos</h6>
                <p className="text-justify">
                  L'application OAZA a pour vocation la gestion des
                  remplacements et des candidatures à l'emploi dans le secteur
                  Café Hôtellerie Restauration.
                </p>
                <p className="text-justify">
                  L'application intègre une solution de sourcing lequel permet à
                  des candidats de s’inscrire et de donner de la force à leur
                  profil et intégrant, au moyen d'un outil de gestion
                  électronique des documents, tous les éléments nécessaires à
                  l'embauche.
                </p>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1  col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6>Plan du site</h6>

                <ul>
                  <li>
                    <Link to={'/mentions-legales'} className="text-white">
                      Mentions légales
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {(facebookURL || linkedinURL || twitterURL) && (
              <div className="col-lg-2 col-md-6 col-sm-6 social-widget">
                <div className="single-footer-widget">
                  <h6>Suivez-nous sur</h6>
                  <div className="footer-social d-flex align-items-center">
                    {facebookURL && (
                      <a href={facebookURL} target="_blank">
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                    )}
                    {linkedinURL && (
                      <a href={linkedinURL} target="_blank">
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                    )}
                    {twitterURL && (
                      <a href={twitterURL}>
                        <FontAwesomeIcon icon={faTwitterSquare} />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-12 mt-5">
              <p className="footer-text text-center">
                Zaggo - Copyright © 2018 - 2019 Tous droits réservés
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
